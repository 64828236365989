@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: theme("colors.black");
    --foreground: hsl(0, 0%, 100%);
    --base-font-family: var(--font-sans);
  }
  .dark {
  }
}
@layer base {
  body,
  html {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
  }

  body {
    @apply text-foreground bg-background;
    font-family: var(--base-font-family);
    font-feature-settings:
      "rlig" 1,
      "calt" 1;
    -webkit-font-smoothing: antialiased;

    font-weight: 300;
    line-height: 1.3;
  }
}
@layer components {
  .row {
    @apply max-w-[75vw] w-full mx-auto;
  }
}
/* https://codepen.io/aldrie/pen/PojGYLo */
.stack {
  display: grid;
  grid-template-columns: 1fr;
}

.stack span {
  grid-row-start: 1;
  grid-column-start: 1;
  --stack-height: calc(100% / var(--stacks) - 1px);
  --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
  --clip-top: calc(var(--stack-height) * var(--index));
  --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
  clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
  animation:
    stack 340ms cubic-bezier(0.46, 0.29, 0, 1.24) 1 backwards
      calc(var(--index) * 120ms),
    glitch 2s ease infinite 2s alternate-reverse;
}

.stack span:nth-child(odd) {
  --glitch-translate: 8px;
}
.stack span:nth-child(even) {
  --glitch-translate: -8px;
}

@keyframes stack {
  0% {
    opacity: 0;
    transform: translateX(-50%);
    text-shadow:
      -2px 3px 0 red,
      2px -3px 0 blue;
  }
  60% {
    opacity: 0.5;
    transform: translateX(50%);
  }
  80% {
    transform: none;
    opacity: 1;
    text-shadow:
      2px -3px 0 red,
      -2px 3px 0 blue;
  }
  100% {
    text-shadow: none;
  }
}

@keyframes glitch {
  0% {
    text-shadow:
      -2px 3px 0 red,
      2px -3px 0 blue;
    transform: translate(var(--glitch-translate));
  }
  2% {
    text-shadow:
      2px -3px 0 red,
      -2px 3px 0 blue;
  }
  4%,
  100% {
    text-shadow: none;
    transform: none;
  }
}
